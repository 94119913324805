@media screen and (max-width: 720px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .Frame {
    width: 720px;
    height: 420px;
    background-image: url("assets/frame_mobile.svg");
    margin-left: calc((100% - 720px) / 2);
    margin-top: 30px;
  }

  .Title {
    width: 330px;
    height: 190px;
    margin-left: calc((100% - 330px) / 2);
    margin-top: 70px;
    /* margin-top: calc((100% - 420px) / 2);  */
    background-image: url("assets/title_mobile.svg");
    /* margin-top: -50px; */
  }

  .MobileCover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-color: #0f1015; */
    background-color: red;
    display: none;
  }

  .primaryBtn {
    /* margin-top: 100px; */
    /* margin: 22px 15px 0px 15px;     */
  }

  .InputField {
    width: 320px;
    height: 48px;
  }

  .InputField-Share {
    width: 320px;
    height: 48px;
  }

  .Poem-Text {
    font-size: 16px;
    padding-top: 0px;
    margin-top: 100px;
    /* max-width: calc(100vw - 30px); */
    width: 320px;
    /* padding-left: 15px;
    padding-right: 15px; */
    /* margin-left: calc((100% - 100vw) / 2); */
    margin-left: calc((100% - 320px) / 2);
    height: auto;
  }

  .PoemFrame {
    width: 720px;
    /* height: 420px; */
    /* height: 930px; */
    height: 100%;
    background-image: url("assets/frame_mobile.svg");
    background-repeat: no-repeat;
    margin-left: calc((100% - 720px) / 2);
    margin-top: 30px;
  }

  .happyChristmas {
    margin-top: 110px;
    /* margin-top: 250px; */
  }

  .Star {
    margin-top: 80px;
  }

  .bg-grey {
    background-color: rgb(15 16 21);
  }

  .Poem-Footer-Container {
    position: relative;
    padding-top: 30px;
    margin-bottom: -20px;
    bottom: 0;
  }

  .primaryBtn {
    position: relative;
    clear: both;
    /* margin-top: 5px; */
  }

  .Btn-Container {
    padding-bottom: 0px;
    padding-top: 60px;
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
    background-color: rgb(15 16 21);
    /* height: 100%;     */
  }

  .Btn-Container-transparent {
    /* margin-top: -200px; */
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
    margin-top: 180px;
    margin-bottom: 25px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .spacer-40 {
    height: 40px;
    /* background-color: rgb(15 16 21); */
  }

  .clear-welcome {
    background-color: rgb(15 16 21);
    width: 100%;
    height: calc(100vh - 704px);
  }

  .clear-keyword {
    background-color: rgb(15 16 21);
    width: 100%;
    height: calc(100vh - 704px);
  }

  .StarEditor {
    margin-top: 80px;
  }

  .Menu-Headline {
    margin-top: 20px;
  }

  .Pattern-Container {
    background-color: rgb(15 16 21);
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
    padding-left: calc((100vw - 170px) / 2);
    flex-direction: column;
  }

  .Card-Footer-Container {
    position: relative;
    bottom: 0;
    background-position: bottom;
    height: 500px;
    background-size: contain;
  }

  .Card-Footer-Container-1 {
    background-image: url("assets/bg-deer-mobile-bot.webp"),
      url("assets/bg-deer-mobile-bot.png");
  }
  .Card-Footer-Container-2 {
    background-image: url("assets/bg-gatsby-mobile-bot.webp"),
      url("assets/bg-gatsby-mobile-bot.png");
  }
  .Card-Footer-Container-3 {
    background-image: url("assets/bg-forest-mobile-bot.webp"),
      url("assets/bg-forest-mobile-bot.png");
  }

  .Theme-1 {
    background-image: url("assets/bg-deer-mobile-top.webp"),
      url("assets/bg-deer-mobile-top.png");
    background-color: rgb(15, 16, 21);
  }
  .Theme-2 {
    background-image: url("assets/bg-gatsby-mobile-top.webp"),
      url("assets/bg-gatsby-mobile-top.png");
    background-color: rgb(15, 16, 21);
  }
  .Theme-3 {
    background-image: url("assets/bg-forest-mobile-top.webp"),
      url("assets/bg-forest-mobile-top.png");
    background-color: rgb(0, 0, 0);
  }

  .ThemedFrame {
    margin-top: 0px;
    /* height: 1195px; */
    height: 100%;
    width: 375px;
    background-position: top;
    background-size: contain;
  }

  .Popup-Container {
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
  }

  .Popup-Box {
    /* display: flex;
    align-items: center;
    flex-direction: column;
  width: 760px;
  height: 760px; */
    background-image: url("assets/share-mobile.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 10px;
    /* color: white;  */
    overflow: hidden;
  }

  .Close-Share {
    bottom: 60px;
    right: calc((100vw - 40px) / 2);
    top: auto;
  }

  .Privacy {
    position: absolute;
    width: 100%;
    height: 30px;
    /* background-color: rgb(15 16 21); */
    margin: 0;
    bottom: 0;
    left: 0;
    font-size: 14px;
    padding-top: 2px;
  }

  .Starting {
    background-size: 90vw;
  }

  .card-buttons {
    margin-top: 0px;
  }
}
