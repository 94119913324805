/* @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=DM+Serif+Text&display=swap'); */
/* @import url('https://fonts.cdnfonts.com/css/satoshi'); */

@font-face {
  font-family: Antiga-Regular;
  src: url("fonts/Antiga-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Serif Text";
  src: url("fonts/DMSerifText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Satoshi";
  src: url("fonts/Satoshi-Variable.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

.mt-50 {
  margin-top: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.o-10 {
  opacity: 0.1;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  caret-color: transparent;
  top: 0;
  width: 100%;
  /* overflow-y: scroll; */
  overflow: hidden;
  position: relative;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.InputField {
  width: 320px;
  height: 54px;
  outline: none;
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-family: "DM Serif Text", serif;
  border: 1px solid white;
  border-radius: 27px;
  background-color: transparent;
  color: white;
  caret-color: transparent;
}

.InputField-Share {
  width: 600px;
  height: 54px;
  outline: none;
  text-align: center;
  margin-top: 380px;
  font-size: 24px;
  font-family: "DM Serif Text", serif;
  border: 1px solid white;
  border-radius: 27px;
  background-color: transparent;
  color: #57585b;
  padding: 0px 20px 0px 20px;
  caret-color: transparent;
}

.InputField-Share:hover {
  cursor: pointer;
}

.Close-Share {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-image: url("assets/close.svg");
  caret-color: transparent;
}

.Close-Share:hover {
  cursor: pointer;
  opacity: 0.5;
}

input:focus {
  caret-color: #be965d;
}

input:focus::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+        */
  /* -webkit-background-clip: text; */
  color: transparent;
}

.HyveLogo {
  /* position: absolute; */
  /* margin-top: -95px; */
  margin-left: calc((100% - 87px) / 2);
  margin-top: 30px;
  width: 87px;
  height: 41px;
  background-image: url("assets/hyveLogo.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.ShareYourChristmas {
  width: 205px;
  height: 86px;
  background-image: url("assets/shareYourChristmas.svg");
  left: calc((100% - 205px) / 2);
  position: absolute;
  margin-top: 180px;
  background-repeat: no-repeat;
}

.Star {
  width: 60px;
  height: 60px;
  background-image: url("assets/star.svg");
  position: absolute;
  left: calc(50% - 30px);
  margin-top: 80px;
  background-repeat: no-repeat;
  z-index: 1;
}

.StarEditor {
  width: 60px;
  height: 60px;
  background-image: url("assets/star.svg");
  position: absolute;
  left: calc(50% - 30px);
  margin-top: 45px;
  background-repeat: no-repeat;
}
.StarAnimated {
  width: 60px;
  height: 60px;
  background-image: url("assets/star.svg");
  position: relative;
  left: calc(50% - 30px);
  /* margin-top: -120px;   */
  animation: rotate360 infinite 20s linear;
  background-repeat: no-repeat;
}

.Creating {
  background-image: url("assets/creating.svg");
  width: 210px;
  height: 130px;
  margin-top: 10px;
  margin-left: calc((100% - 210px) / 2);
  background-repeat: no-repeat;
}

.CreatingCard {
  background-image: url("assets/creatingPostcard.svg");
  width: 250px;
  height: 130px;
  margin-top: 10px;
  margin-left: calc((100% - 250px) / 2);
  background-repeat: no-repeat;
}

.CardContent {
  /* display: flex; */
  /* flex-direction: column; */
  position: relative;
  align-items: center;
  justify-content: center;
  /* height: 100%;   */
  padding-top: 150px;
}

.CenteredBox {
  position: absolute;
  width: 250px;
  height: 250px;
  left: calc((100vw - 250px) / 2);
  top: calc((100vh - 250px) / 2);
}

.Poem-Title {
  /* font-family: 'DM Serif Text', serif; */
  font-family: Antiga-Regular;
  font-size: 40px;
  background-clip: text;
  background-image: linear-gradient(
    107.87deg,
    #be965d -1.59%,
    #976f40 28.63%,
    #c99b61 44.82%,
    #f0be7b 58.84%,
    #ffcb85 66.4%,
    #eebc79 75.03%,
    #c0935b 92.3%,
    #976f40 106.33%
  );
  -webkit-text-fill-color: transparent;
}

.Poem-Text-2 {
  margin-top: 40px;
  column-count: 2;
  column-gap: 50px;

  font-family: "DM Serif Text", serif;
  font-size: 18px;

  text-align: left;
  white-space: pre-wrap;
}

.Poem-Text {
  padding-top: 80px;
  font-family: "DM Serif Text", serif;
  font-size: 18px;

  text-align: center;
  white-space: pre-wrap;

  height: 370px;
  overflow: hidden;
}

.Card-Footer-Container-1 {
  /* position: absolute; */
  /* bottom: -70px;   */
  position: relative;
  bottom: 0;
  bottom: -10px;
  width: 100%;
}
.Card-Footer-Container-2 {
  position: absolute;
  bottom: -100px;
  width: 100%;
}
.Card-Footer-Container-3 {
  position: absolute;
  bottom: -110px;
  width: 100%;
}

.Poem-Footer-Container {
  position: absolute;
  bottom: 75px;
  width: 100%;
}

.Poem-Footer {
  background-clip: text;
  background-image: linear-gradient(
    107.87deg,
    #be965d -1.59%,
    #976f40 28.63%,
    #c99b61 44.82%,
    #f0be7b 58.84%,
    #ffcb85 66.4%,
    #eebc79 75.03%,
    #c0935b 92.3%,
    #976f40 106.33%
  );
  -webkit-text-fill-color: transparent;
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  /* bottom: 120px; */
  width: 280px;
  left: calc((100% - 250px) / 2);
  height: 50px;
}

.Btn-Container {
  padding-bottom: 30px;
}

.Btn-Container-transparent {
  padding-bottom: 30px;
}

.Logo-Footer {
  position: relative;
  /* bottom: 70px;     */
  left: calc((100% - 58px) / 2);
  width: 58px;
  height: 27px;
  background-image: url("assets/hyveLogo.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.AI-footer {
  font-size: 14px;
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
  background-clip: text;
  background-image: linear-gradient(
    107.87deg,
    #be965d -1.59%,
    #976f40 28.63%,
    #c99b61 44.82%,
    #f0be7b 58.84%,
    #ffcb85 66.4%,
    #eebc79 75.03%,
    #c0935b 92.3%,
    #976f40 106.33%
  );
  -webkit-text-fill-color: transparent;
  position: absolute;
  bottom: 35px;
  width: 260px;
  left: calc((100% - 250px) / 2);
}

.primaryBtn {
  /* background-color: black; */
  font-family: "DM Serif Text", serif;
  background: transparent;
  background-image: url("assets/button.svg");
  border: none;
  /* border: 2px solid #FFCB85; */
  /* padding: 0px 40px 0px 40px; */
  height: 60px;
  /* border-radius: 60px; */

  transition: 0.25s;
  color: white;
  font-size: 20px;
  width: 260px;
  /* opacity: 0.7; */
  margin: 22px 15px 0px 15px;
  /* margin-left: calc((100% - 260px) / 2); */
}

.primaryBtn:hover {
  background-image: url("assets/button-hover.svg");
}

.primaryBtn p {
  margin: 0;
  height: 60px;
  vertical-align: middle;
  line-height: 60px;
  transition: 0.5s;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-image: linear-gradient(rgb(253, 11, 11) 100%, white 0%);; */
  background-color: white;
}

.primaryBtn p:hover {
  cursor: pointer;
  background-image: linear-gradient(
    107.87deg,
    #be965d -1.59%,
    #976f40 28.63%,
    #c99b61 44.82%,
    #f0be7b 58.84%,
    #ffcb85 66.4%,
    #eebc79 75.03%,
    #c0935b 92.3%,
    #976f40 106.33%
  );
}

.primaryBtn:disabled {
  background-image: url("assets/button.svg");
  cursor: pointer;
  opacity: 0.3;
}

.primaryBtn:focus {
  outline: none;
  background-image: url("assets/button-hover.svg");
}

.spacer-20 {
  height: 20px;
}

.CardContent hr {
  width: 500px;
  border: #fcc883 solid 0.5px;
}

.Title {
  width: 780px;
  height: 420px;
  margin-left: calc((100% - 780px) / 2);
  /* margin-top: calc((100% - 420px) / 2);  */
  background-image: url("assets/title.svg");
  /* margin-top: -50px; */
}

.Title-Short {
  width: 330px;
  height: 130px;
  margin-left: calc((100% - 330px) / 2);
  margin-top: 70px;
  background-image: url("assets/title_mobile.svg");
  background-size: 100%;
}

.Frame {
  margin-top: 60px;
  margin-left: calc((100% - 1007px) / 2);
  width: 1007px;
  height: 758px;
  font-size: calc(14px + 1vmin);
  background-image: url("assets/frame.svg");
}

.Starting {
  margin: 0;
  width: 100vw;
  height: 90vh;
  background-position: center;
  background-size: 540px, 480px;
  background-repeat: no-repeat;
  background-image: url("assets/starting.svg");
}

.fadeOut {
  animation: fadeOut-animation;
  animation-duration: 1.1s;
  animation-delay: 4s;
}

@keyframes fadeOut-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes fadeIn-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Loading-Bar {
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    107.87deg,
    #be965d -1.59%,
    #976f40 28.63%,
    #c99b61 44.82%,
    #f0be7b 58.84%,
    #ffcb85 66.4%,
    #eebc79 75.03%,
    #c0935b 92.3%,
    #976f40 106.33%
  );
  animation-name: load;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
}

@keyframes load {
  from {
    width: 0vw;
  }
  to {
    width: 100vw;
  }
}

.PoemFrame {
  position: relative;
  margin-top: 60px;
  margin-left: calc((100% - 1007px) / 2);
  /* margin-left: auto;   */
  /* margin-right: auto;  */
  width: 1007px;
  height: 758px;
  font-size: calc(14px + 1vmin);
  background-image: url("assets/poemFrame.svg");
}

.ThemedFrame {
  margin-top: 20px;
  width: 100%;
  max-width: 1440px;
  height: 1024px;
  margin-left: auto;
  margin-right: auto;
  /* height: 100%; */
  font-size: calc(14px + 1vmin);
  display: flex;
  flex-direction: column;
  background-position-x: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.452);
  background-size: auto 1024px;
}

.happyChristmas {
  background-image: url("assets/happyChristmas.svg");
  width: 230px;
  height: 100px;
  margin-left: calc((100% - 230px) / 2);
  margin-top: 120px;
  margin-bottom: -70px;
}

.card-buttons {
  margin-top: -115px;
}

.Theme-1 {
  background-image: url("assets/bg-deer@2x.jpg");
}
.Theme-2 {
  background-image: url("assets/bg-gatsby@2x.jpg");
}
.Theme-3 {
  background-image: url("assets/bg-forest@2x.jpg");
}
.Pattern1 {
  background-image: url("assets/theme-deer@2x.png");
}
.Pattern2 {
  background-image: url("assets/theme-gatsby@2x.png");
}
.Pattern3 {
  background-image: url("assets/theme-forest@2x.png");
}

.Pattern-Item {
  border: 2px solid #be965d00;
  border-radius: 76px;
  width: 152px;
  height: 152px;
  margin: 0;
  margin: 0px 15px 15px 15px;
  transition: 0.25s;
}

.Pattern-Item:hover {
  border-color: #be965d;
  /* box-shadow: 0px 0px 15px #be965d; */
  cursor: pointer;
}

.Pattern {
  width: 152px;
  height: 152px;
  border-radius: 75px;
  background-size: contain;

  /* opacity: 0.5; */
  transition: 0.25;
}

.Active {
  border-color: #be965d !important;
}

.Pattern-Container {
  padding-top: 30px;
  width: calc(3 * (152 + 30) px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: "DM Serif Text", serif;
  font-size: 18px;
  height: 100%;
  margin-left: calc((100% - 540px) / 2);
}

.Menu-Headline {
  font-family: "DM Serif Text", serif;
  font-size: 20px;
  margin-top: 140px;
}

.Menu-Headline-Keyword {
  font-family: "DM Serif Text", serif;
  font-size: 20px;
  margin-top: 200px;
}

.Particles {
  /* background: linear-gradient(0deg, rgb(130, 87, 39) 0%, rgb(2, 25, 41) 100%); width: 100%; height: 100%; */
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(44, 47, 53, 1) 0%,
    rgba(23, 24, 30, 1) 100%
  );
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -1;
  transition: opacity 2s;
}

.z-2 {
  z-index: -2;
}

.bg {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(44, 47, 53, 1) 0%,
    rgba(23, 24, 30, 1) 100%
  );
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 0;
}

.Popup-Container {
  position: fixed;
  z-index: 5;
  background: #000;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Popup-Box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 760px;
  height: 760px;
  background-image: url("assets/share.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: white;
}

.Privacy {
  position: absolute;
  bottom: 20px;
  right: 40px;
}

.Privacy a {
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  transition: color 0.5s;
  font-size: 16px;
}

.Privacy a:hover {
  color: rgba(255, 255, 255, 0.6);
}

/* KANN RAUS */

.GoButton {
  outline: none;
  height: 45px;
  padding: 0px 10px 0px 10px;
  float: right;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  transition: 0.5s all;
  margin: 0px 0px 0 15px;
}

.GoButton:focus {
  outline: 6px solid #41b6e655;
}

.GoButton:disabled {
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
}

.GoButton:hover:enabled {
  color: #41b6e6;
  border: 2px solid #41b6e6;
  cursor: pointer;
}

.Hyve {
  padding-top: 15px;
  color: #41b6e6;
  text-align: left;
  font-size: calc(10px + 1vmin);
}

.contentRow {
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.shareLink {
  color: darksalmon;
  text-align: left;
  text-decoration: none;
  font-size: calc(10px + 1vmin);
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

.happyChristmas-Poem {
  margin-top: 50px;
  background-image: url("assets/happyChristmas.svg");
  width: 230px;
  height: 100px;
  margin-left: calc((100% - 230px) / 2);
  margin-bottom: -70px;
}

.sphere {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

.sphere img {
  display: block; /* Remove any space below the image */
  opacity: 0.7; /* Adjust as needed */
}

.sphere::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: radial-gradient(
    circle at 20% 30%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 10%,
    rgba(255, 255, 255, 0) 11%,
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 75%
  ); */
  z-index: 1;
}

.snow-globe-container {
  width: 346px;
  height: 346px;
  margin-top: 85px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.snow-globe-container img {
  width: 350px;
  height: 350px;
  position: absolute;
  top: -2px;
  left: -2px;
}

.snow-globe {
  width: 350px;
  height: 350px;
  background-image: url("assets/globe-dark.png");
  background-repeat: no-repeat;
  mix-blend-mode: hard-light;
  opacity: 0.6;
  z-index: 1;
  border-radius: 50%;
}

.snow-globe-gradient {
  width: 350px;
  height: 350px;
  background: radial-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0, 0) 60%,
    rgb(0, 0, 0, 100) 75%
  );
  z-index: 2;
}

.snow-globe-img {
  z-index: 0;
  border-radius: 50%;
}

.Particles-globe {
  /* background: linear-gradient(0deg, rgb(130, 87, 39) 0%, rgb(2, 25, 41) 100%); width: 100%; height: 100%; */
  background: none;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 3;
  transition: opacity 2s;
}

.play-button-container {
  z-index: 4;
  position: absolute;
  top: 260px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
}

.loader {
  margin-top: 380px;
}
